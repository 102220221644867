<template>
  <div class="c-app flex-row align-items-center" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol lg="5">
          <CCard class="p-3">
            <CCardBody>
              <CForm>
                <h2>ログイン</h2>
                <CRow>
                  <CCol v-if="false" class="col-lg-5 col-12">
                    <CInput
                      placeholder="ID"
                    >
                      <template #prepend-content>
                        <CIcon name="cil-house"/>
                      </template>
                    </CInput>
                  </CCol>
                  <CCol class="col-lg-7 col-12">
                    <CInput
                      placeholder="ユーザー名"
                      v-model="username"
                    >
                      <template #prepend-content>
                        <CIcon name="cil-user"/>
                      </template>
                    </CInput>
                  </CCol>
                </CRow>
                <CInput
                  placeholder="パスワード"
                  v-model="password"
                  type="password"
                >
                  <template #prepend-content>
                    <CIcon name="cil-lock-locked"/>
                  </template>
                </CInput>
                <CRow>
                  <CCol col="6" class="text-left">
                    <CButton
                      @click="login"
                      color="primary" class="px-4">ログイン</CButton>
                  </CCol>
                  <CCol col="6" class="text-right">
                    <CButton color="link" class="px-0">パスワードを忘れた</CButton>
                    <CButton color="link" class="d-lg-none">Register now!</CButton>
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import Comm from '@/views/v1/lib/Comm.js'

export default {
  name: 'Login',
  mixins: [Comm],/* ここでミックスインを追加 */
  data() {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    async login() {
      const response = await this._login(this.username, this.password)
    }
  }

}
</script>
